<template>
  <form
    action="/search"
    method="GET"
    @submit.prevent="handleSearch(modelValue)"
  >
    <Combobox v-model="selected" nullable>
      <div class="relative rounded-full shadow-sm mx-auto max-w-md">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
        </div>
        <ComboboxInput
          id="searchString"
          ref="searchFieldRef"
          type="search"
          name="searchString"
          class="block w-full rounded-full border-0 px-10 text-gray-900 bg-white ring-1 ring-inset ring-gray-300 appearance-none placeholder:text-gray-400 focus:ring-2 focus:ring-gray-200 focus:outline-0 sm:text-sm sm:leading-6 input"
          :class="{ 'py-3': !mini, 'py-2.5': mini }"
          placeholder="Location, name or specialty"
          autocomplete="off"
          autocapitalize="off"
          autocorrect="off"
          spellcheck="false"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          @change="fetchTypeahead($event.target.value)"
        />
        <div class="absolute inset-y-0 right-0 flex items-center pr-3">
          <button
            v-if="modelValue"
            type="button"
            class="flex justify-center flex-col items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 w-8 h-8"
            @click="
              $emit('update:modelValue', '');
              hits = [];
              $refs.searchFieldRef.el.focus();
            "
          >
            <XMarkIcon class="h-4 w-4" />
          </button>
        </div>

        <TransitionRoot
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            v-if="modelValue && hits.length"
            class="absolute mt-1 sm:max-h-60 h-screen w-full overflow-auto rounded-md bg-white py-1 text-base sm:shadow-lg sm:ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ComboboxOption
              v-if="modelValue"
              v-slot="{ selected, active }"
              :value="modelValue"
              class="hidden"
            >
              <li
                class="relative cursor-default select-none py-2 sm:pl-5 pl-3 pr-4 flex items-center"
                :class="{
                  'sm:bg-gray-200 text-gray-900': active,
                  'text-gray-900': !active,
                }"
              >
                <MagnifyingGlassIcon class="h-4 w-4 ml-2" />
                <span class="block truncate ml-4">
                  {{ modelValue }}
                </span>
              </li>
            </ComboboxOption>
            <ComboboxOption
              v-for="person in hits"
              :key="person.id"
              v-slot="{ selected, active }"
              as="template"
              :value="person"
            >
              <li
                class="relative cursor-default select-none py-2 sm:pl-5 pl-3 pr-4"
                :class="{
                  'sm:bg-gray-200 text-gray-900': active,
                  'text-gray-900': !active,
                }"
              >
                <div v-if="person.slug" class="flex items-center gap-x-2">
                  <ProfileImage
                    :provider="person"
                    class="h-8 w-8"
                    size="small"
                  />

                  <div class="w-72">
                    <div class="truncate">{{ person.name }}</div>
                    <div
                      v-if="!person.isCompany"
                      class="truncate text-xs text-gray-500"
                    >
                      {{ person.companyName }}
                    </div>
                  </div>
                </div>
                <div v-else class="flex items-center">
                  <TagIcon
                    v-if="
                      person.docType === 'category' ||
                      person.docType === 'specialty' ||
                      person.docType === 'service'
                    "
                    class="h-4 w-4 ml-2"
                  />
                  <MapPinIcon
                    v-else-if="person.docType === 'location'"
                    class="h-4 w-4 ml-2"
                  />
                  <MagnifyingGlassIcon v-else class="h-4 w-4 ml-2" />
                  <span class="block truncate ml-4">
                    {{ person.name }}
                  </span>
                </div>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </form>
</template>
<script setup>
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  MapPinIcon,
  TagIcon,
} from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import { useWindowSize } from '@vueuse/core';

const { width, height } = useWindowSize();

const props = defineProps([
  'modelValue',
  'mini',
  'autofocus',
  'searchFieldRef',
  'autofocus',
]);
const emit = defineEmits(['update:modelValue', 'update:selected']);
const hits = ref([]);
const searchFieldRef = ref(null);

const selected = ref(null);
const queryString = ref('');
const route = useRoute();

const getRegionCode = () => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  return locale.split('-')[1] || 'us'; // Extract region part if available
};

let GoogleAutocompleteSuggestion = null;
let autoCompleteToken = null;
let GoogleSpherical;
onMounted(async () => {
  const loader = await useGoogleMapLoader();
  const { AutocompleteSuggestion, AutocompleteSessionToken } =
    await loader.importLibrary('places');

  const { spherical } = await loader.importLibrary('geometry');

  autoCompleteToken = new AutocompleteSessionToken();
  GoogleAutocompleteSuggestion = AutocompleteSuggestion;
  GoogleSpherical = spherical;
});

const fetchTypeahead = useDebounceFn(async (query) => {
  const userLocation = useUserLocation();
  const data = await $fetch('/api/typeahead', {
    query: {
      query: query ?? '',
      aroundLatLng: `${userLocation.value.latitude}, ${userLocation.value.longitude}`,
    },
  });

  queryString.value = query;

  const request = {
    input: query,
    includedPrimaryTypes: [
      'locality',
      'sublocality',
      'political',
      'postal_code',
      'administrative_area_level_1',
    ],
    language: 'en-US',
    sessionToken: autoCompleteToken,
    origin: {
      lat: userLocation.value.latitude,
      lng: userLocation.value.longitude,
    },
    includedRegionCodes: [getRegionCode()],
  };

  const typeaheadData = data.filter((hit, index) => index < 10);

  if (GoogleAutocompleteSuggestion && query.length > 2) {
    const { suggestions } =
      await GoogleAutocompleteSuggestion.fetchAutocompleteSuggestions(request);
    if (suggestions.length > 0) {
      const suggestion = suggestions[0].placePrediction;
      if (
        suggestion.distanceMeters < 50000 ||
        containState(query, suggestion?.secondaryText?.toString() ?? '') ||
        suggestion.types.includes('postal_code') ||
        suggestion.types.includes('administrative_area_level_1') ||
        typeaheadData.length === 0
      ) {
        typeaheadData.unshift({
          name: suggestion.text.toString(),
          docType: 'location',
          place: suggestion.toPlace(),
          locationType: suggestion.types[0],
        });
      }
    }
  }
  hits.value = typeaheadData;
}, 80);

const containState = (query, secondaryText) => {
  const state = query.split(/,| /).pop();
  return secondaryText.toLowerCase().includes(state.toLowerCase());
};

const isQueryMatchingTopHit = (query) => {
  if (typeof query !== 'string') return false;
  if (!hits.value || hits.value.length === 0) return false;
  const topHit = hits.value[0];
  if (!topHit?.name) return false;
  if (
    !['location', 'specialty', 'service', 'category'].includes(topHit.docType)
  ) {
    return false;
  }

  if (hits.value.length === 1) {
    // there no other hits, default to first hit
    return true;
  }
  // return New York for New York, USA
  const topHitLocale = topHit.name.toString().split(',')[0];

  return topHitLocale.toLowerCase() === query.toLowerCase();
};

const handleSearch = async (searchQuery) => {
  let newValue = searchQuery ?? selected.value;
  if (!newValue) newValue = queryString.value;

  if (isQueryMatchingTopHit(newValue)) {
    newValue = hits.value[0];
  }

  if (newValue.slug) {
    await navigateTo(getProviderLink(newValue));
  } else if (newValue.place) {
    await newValue.place.fetchFields({
      fields: ['viewport', 'displayName'],
    });
    const viewport = newValue.place.viewport;
    const radius = GoogleSpherical.computeDistanceBetween(
      viewport.getCenter(),
      viewport.getNorthEast(),
    );
    const params = {
      lat: viewport.getCenter().lat(),
      lng: viewport.getCenter().lng(),
      zoom: calculateZoomLevel(width.value, height.value / 2, viewport),
      r: radius / 1000,
    };

    params.q = newValue.place.displayName;
    params.location = 1;

    const paramString = new URLSearchParams(params).toString();
    await navigateTo(`/search?${paramString}`);
  } else if (newValue.docType === 'specialty') {
    await navigateTo(`/search?filter=specialty=${newValue.name}`);
  } else if (newValue.docType === 'service') {
    await navigateTo(`/search?filter=services=${newValue.name}`);
  } else if (newValue.docType === 'category') {
    switch (newValue.name) {
      case 'Intensive':
        await navigateTo(`/search?filter=offerIntensive=true`);
        break;

      case 'Take Insurance':
        await navigateTo(`/search?filter=takeInsurance=true`);
        break;
    }
    return;
  } else {
    const params = {};

    if (route.query.filter) {
      params.filter = route.query.filter;
    }
    params.q = newValue;

    const paramString = new URLSearchParams(params).toString();
    await navigateTo(`/search?${paramString}`);
  }
};

watch(
  () => selected.value,
  (newValue) => {
    handleSearch();
  },
);
</script>
<style>
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>
